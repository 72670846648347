import React from 'react';
import Helmet from 'react-helmet';

import HeaderComponent from '../components/HeaderComponent';
import Layout from '../components/layout';
import WorkshopOptions from '../components/WorkshopOptions';
import Contact from '../components/Contact';
import GetToKnow from '../components/GetToKnow';
import TheClearing from '../components/TheClearing';
import WineAndWonder from '../components/WineAndWonder';
import workshopHero from '../assets/images/workshop-hero.jpg';


const Coaching = () => {
  const siteTitle = 'Workshops | Experiment With Wonder';

  return (
    <Layout>
      <Helmet title={siteTitle}></Helmet>
      <HeaderComponent
        buttonType="workshop"
        backgroundImg={workshopHero}
      >
        <strong>Live Workshops<br /><span className="space">&nbsp;</span>to Explore&nbsp;Life’s&nbsp;Possibilities</strong>
      </HeaderComponent>
      <WorkshopOptions />
      <TheClearing />
      <WineAndWonder />
      <GetToKnow />
      <Contact />
    </Layout>
  );
}

export default Coaching;